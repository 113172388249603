<template>
  <div>
    <sidenav
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :item-edit.sync="itemEdit"
      @refetch-data="refetchData"
      :center-options="centerOptions"
      :sector-options="sectorOptions"
      :type-options="cameraTypeOptions"
      :unit-options="unitOptions"
      v-if="showFilters"
    />
    <b-row v-if="showFilters">
      <b-col cols="6">
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
      </b-col>

      <b-col cols="6" class="pr-3 my-2 text-right">
        <button-filters
          @show-modal="$root.$emit('bv::show::modal', $event)"
          :filters-count="filtersCount"
          @clear-search-filters="clearSearchFilters"
        />
      </b-col>
    </b-row>

    <!-- Filters -->
    <filter-modal
      @action="buildFilters()"
      @clear="clearSearchFilters()"
      v-if="showFilters"
    >
      <template v-slot:main-filters>
        <filters
          :center-filter.sync="centerFilter"
          :center-options="centerOptions"
          :sector-filter.sync="sectorFilter"
          :sector-options="sectorOptions"
          :tenant-filter.sync="tenantFilter"
          :tenant-options="tenantOptions"
          :reseller-filter.sync="resellerFilter"
          :reseller-options="resellerOptions"
          :online-filter.sync="onlineFilter"
          :rtsp-available-filter.sync="rtspAvailableFilter"
          :enabled-smart-detection-filter.sync="enabledSmartDetectionFilter"
          :enabled-person-smart-detection-filter.sync="enabledPersonSmartDetectionFilter"
          :button-update="buttonUpdate"
        />
      </template>
      <template v-slot:footer-buttons>
        <b-button
          variant="secondary"
          size="sm"
          class="text-capitalize"
          :disabled="buttonUpdate"
          @click="updateCameras()"
        >
          {{ $t("actions.update") + " " + $tc("camera.title", 2) }}
        </b-button>
      </template>
    </filter-modal>

    <toastification-loading-content ref="loadingToast" />

    <!-- Table Container Card -->
    <b-card-group deck :class="{ 'mx-2': showFilters, 'm-0': !showFilters }">
    <b-card no-body class="m-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }} </label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button
                @click="exportExcel()"
                variant="success"
                class="text-capitalize"
                type="button"
                :disabled="buttonExport"
              >
                <feather-icon icon="DownloadCloudIcon" size="15" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(reseller)="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(tenants)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(units)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(center)="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(sector)="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(type)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #cell()="data">
          <div
            v-clipboard:copy="`${data.value}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="cursor: pointer"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(password)="data">
          <div
            v-clipboard:copy="`${data.value}`"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            style="cursor: pointer"
          >
            <feather-icon icon="CopyIcon" size="16" class="align-middle text-body" />
          </div>
        </template>

        <!-- Column: Preview -->
        <template #cell(name)="data">
          <div style="position: relative; width: 250px">
            <img
              :src="`${snapshotUrl}${data.item.camera.id}?access_token=${token}`"
              style="width: 250px; border-radius: 16px"
            />
            <div
              style="
                z-index: 1;
                position: absolute;
                width: -webkit-fill-available;
                color: white;
                background: rgba(0, 0, 0, 0.5);
                padding: 5px;
                top: 0;
                right: 0;
                left: 0;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
              "
            >
              <b-media vertical-align="center">
                <template #aside>
                  <div
                    :style="`background: ${data.item.camera.online ? 'green' : 'red'};
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 1rem;
                            text-align: center;`"
                  ></div>
                </template>
                <small style="font-weight: bolder; font-size: 14px">{{
                  data.item.name
                }}</small>
              </b-media>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="updateStatusCamera(data.item.camera.id)">
              <feather-icon icon="RefreshCwIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.update") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item @click="openEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.edit") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t("actions.delete") }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                data.item.camera.rtsp_available && $can('refresh_flow', 'CameraProxy')
              "
              @click="refreshRtpsFlow(data.item)"
            >
              <feather-icon icon="CastIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ `${$t("actions.refresh")} RTSP` }}
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    </b-card-group>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BToast,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";
import store from "@/store";
import Pagination from "@/components/Pagination.vue";
import axios from "axios";
import FilterModal from "@/components/Filters/FilterModal.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import ButtonFilters from "@/components/Filters/ButtonFilters.vue";

export default {
  components: {
    Sidenav,
    Breadcrumb,
    Filters,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BToast,
    ToastificationLoadingContent,
    vSelect,
    FilterModal,
    ButtonFilters,
  },
  props: {
    filters: {
      type: Object | Array,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    //start pagination
    const { t } = useI18nUtils();
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const isEditSidebarActive = ref(false);
    const itemEdit = ref({});
    const buttonExport = ref(false);
    const buttonUpdate = ref(false);
    const camerasUpdated = ref(0);
    const camerasNew = ref(0);
    const camerasFinish = ref(0);

    const snapshotUrl = `${process.env.VUE_APP_BASE_URL}/v1/services/snapshot/`;

    const loadingToast = ref(null);

    const openEdit = (item) => {
      isEditSidebarActive.value = true;
      itemEdit.value = item;
    };

    const toast = useToast();

    const exportExcel = (ctx, callback) => {
      buttonExport.value = true;
      loadingToast.value.show("Descarga", "Generando archivo...", "secondary");

      store
        .dispatch("cm_camera/export", {
          q: searchQuery.value,
          max: perPage.value,
          "page[n]": currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          id_reseller: resellerFilter.value,
          id_center: centerFilter.value,
          id_sector: sectorFilter.value,
          id_tenant: tenantFilter.value,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "template.xlsx");
          document.body.appendChild(link);
          link.click();
          buttonExport.value = false;
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Error exportando la información",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          buttonExport.value = false;
        })
        .finally(() => {
          loadingToast.value.close();
        });
    };

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      refListTable,
      refetchData,
      formatDateAssigned,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraTypeOptions,
      unitOptions,
      onlineFilter,
      rtspAvailableFilter,
      enabledSmartDetectionFilter,
      enabledPersonSmartDetectionFilter,
      applyChartFilters,

      //filtersCount
      clearSearchFilters,
      filtersCount,
      buildFilters,
    } = useList(props);

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: context.parent.$i18n.t("messages.delete.title"),
        text: context.parent.$i18n.t("messages.delete.body"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: context.parent.$i18n.t("actions.delete"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          loadingToast.value.show("Eliminando", `Eliminando cámara ...`, "secondary");
          storeInner
            .dispatch("cm_camera/delete", id)
            .then((response) => {
              if (response.success) {
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
              loadingToast.value.close();
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
              loadingToast.value.close();
            });
        }
      });
    };

    const updateCameras = () => {
      if (!resellerFilter.value || resellerFilter.value.length === 0) {
        return toast({
          component: ToastificationContent,
          props: {
            title: context.parent.$i18n.t("camera.resellers_validation"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }

      loadingToast.value.show("Actualizando", `Actualizando cámaras ...`, "secondary");
      buttonUpdate.value = true;

      store
        .dispatch("cm_camera/update", {
          id_resellers: resellerFilter.value,
        })
        .then((response) => {
          if (response.success) {
            let resellers = 0;

            if (!window.Echo.connector.socket.connected) window.Echo.connect();

            window.Echo.private(`cameras-updating-${response.data.jobId}`).listen(
              ".update",
              (data) => {
                if (data.message.event == "enqueue") {
                  camerasUpdated.value += data.message.cameras_count;

                  resellers += 1;

                  if (
                    camerasUpdated.value == 0 &&
                    resellers == resellerFilter.value.length
                  ) {
                    loadingToast.value.close();
                    buttonUpdate.value = false;
                  } else
                    loadingToast.value.show(
                      "Actualizando",
                      `Actualizando ${camerasFinish.value} De ${camerasUpdated.value} Cámaras`,
                      "secondary"
                    );
                } else if (data.message.event == "finish") {
                  loadingToast.value.show(
                    "Actualizando",
                    `Actualizando ${camerasFinish.value} De ${camerasUpdated.value} Cámaras`,
                    "secondary"
                  );
                  if (data.message.isNew) {
                    camerasNew.value += 1;
                  }
                  camerasFinish.value += 1;

                  if (camerasFinish.value == camerasUpdated.value) {
                    loadingToast.value.close();
                    toast({
                      component: ToastificationContent,
                      props: {
                        title: "Actualizado Correctamente",
                        icon: "CheckIcon",
                        variant: "success",
                      },
                    });
                    refetchData();
                    buttonUpdate.value = false;
                    camerasUpdated.value = 0;
                    camerasNew.value = 0;
                    camerasFinish.value = 0;
                  }
                }
              }
            );
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
            buttonUpdate.value = false;
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const updateStatusCamera = (id) => {
      store
        .dispatch("camera_proxy/updateStatus", id)
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          refetchData();
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const onCopy = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Copiado correctamente",
          icon: "ClipboardIcon",
          variant: "success",
        },
      });
    };

    const onError = () => {
      toast({
        component: ToastificationContent,
        props: {
          title: "No se pudo copiar",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const refreshRtpsFlow = (camera) => {
      axios
        .get(`${process.env.VUE_APP_MS_RTSP_URL}refresh`, {
          params: { id: camera.camera.id },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Host": process.env.VUE_APP_BASE_URL,
          },
        })
        .then((result) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Actualizadó correctamente",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });
    };
    applyChartFilters(props.filters);

    let itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.camera_manager.cameras");
    // Trash Icon Filter

    return {
      itemFilter,
      addItemFilterSearch,
      nameNav,
      isEditSidebarActive,
      itemEdit,
      openEdit,
      exportExcel,
      buttonExport,
      buttonUpdate,
      update,
      updateCameras,
      updateStatusCamera,
      confirmDelete,
      loadingToast,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      centerOptions,
      centerFilter,
      sectorOptions,
      sectorFilter,
      tenantOptions,
      tenantFilter,
      resellerOptions,
      resellerFilter,
      cameraTypeOptions,
      unitOptions,
      onlineFilter,
      rtspAvailableFilter,
      enabledSmartDetectionFilter,
      enabledPersonSmartDetectionFilter,
      onCopy,
      onError,
      refreshRtpsFlow,
      snapshotUrl,
      // filterCounter
      filtersCount,
      buildFilters,
      clearSearchFilters,
      token: localStorage.accessToken,
    };
  },
};
</script>

<style lang="scss">
.mac-td {
  min-width: 180px;
}
</style>
